import axios from 'axios';

export const tabApi = () => axios.get('/api/app/categorys');

export const list = (params) => axios.get(`/api/app/bannerList?categoryId=${params.categoryId}&pageNo=${params.pageNo}&pageSize=${params.pageSize}`);

export const detail = (params) => axios.get(`/api/app/banner/detail/${params.id}`);

export const radomDetail = (params) => axios.get(`/api/app/banner/random/${params.id}`);

