<template>
  <div class="home">
    <nav-bar :navList="navList" v-model:tabIndex="tabIndex" @tabChange="tabChange" />
    <div class="container" v-if="show">
      <info-list :list="source" />
      <VueEternalLoading :load="load" v-model:is-initial="isInitial">
        <template #loading>
          <div class="lodingStatus my-loading">
            加载中...
          </div>
        </template>
        <template #no-more>
          <div class="lodingStatus my-no-more">
            没有更多了
          </div>
        </template>

        <template #no-results>
          <div class="lodingStatus my-no-results">
            没有数据了
          </div>
        </template>
      </VueEternalLoading>
    </div>
  </div>
  <div class="icp">
      <label>{{icpText}}</label>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import navBar from "@/components/nav-bar.vue";
import infoList from "@/components/info-list.vue";
import { VueEternalLoading } from '@ts-pro/vue-eternal-loading';
import { list, tabApi } from '@/api';

const page = ref(1);
const pageSize = ref(20);
const source = ref([]);
const navList = ref([]);

const tabIndex = ref(0);

const show = ref(false);

const isInitial = ref(true);
var icpText = "";
const getTabList = async () => {
  const res = await tabApi();
  debugger;
  const result = res.data.data || [];
  if (result) {
    navList.value = result;
    show.value = true;
  }
}

onMounted(() => {
  getTabList();
  setICP();
});
const setICP  = async ()=>{
  if(window.location.hostname.startsWith("anxin1369")){
    icpText = "京ICP备2024056580号-2";
  } else if(window.location.hostname.startsWith("anxin168")){
    icpText = "京ICP备2024056580号-4";
  } else if(window.location.hostname.startsWith("anxin2004")){
    icpText = "京ICP备2024056580号-3";
  } else {
    icpText = "京ICP备2024056580号-1";
  }
}
const tabChange = async () => {
  source.value = [];
  page.value = 1;
  isInitial.value = true;
}

const querySelect = async () => {
  const res = await list({
    categoryId: navList.value[tabIndex.value]?.id,
    pageNo: page.value,
    pageSize: pageSize.value,
  });

  const result = res.data.data;

  if (result.records) {
    page.value = page.value + 1;
    source.value = source.value.concat(result.records);
  }
  return result;
}


const load = async ({ loaded, noMore, noResults }) => {
  const result = await querySelect();
  if (result.records) {
    if (result.records?.length == 0) {
      noMore();
      return;
    } else {
      loaded(result.records?.length, pageSize.value);
    }
  } else {
    noResults();
  }
}



</script>

<style scoped>
.home {
  background: rgb(239, 243, 248);
}

.container {
  background: #fff;
}

.lodingStatus {
  text-align: center;
  font-size: 24px;
}
.icp {
  font-size: 25px;
  background: #00000000;
  text-align: center;
  left: 20px;
  right: 20px;
  bottom: 20px;   /* 距离底部20像素 */
  position: fixed; /* 或 absolute, fixed, 依赖于需求 */
  z-index: 9999; /* 设置一个很高的值确保在最上层 */
}
</style>