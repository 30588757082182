<template>
  <div class="list-wrap">
    <div class="list-container">
      <template v-for="(item, idx) in props.list" :key="idx">
        <ListItem :data="item" />
      </template>
    </div>

  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import ListItem from "./list-item.vue";

const props = defineProps({
  list: Array
});

</script>

<style lang="less">
.list-wrap {
  margin-top: 48px;
  background: #fff;
  padding-top: 24px;

  .list-container {
    padding: 24px;

    .textImg {
      .adm-list-item-content {
        display: flex;
        padding: 24px 0;
        border-bottom: 1px solid #eee;


        .adm-image {
          width: 240px;

          &>img {
            width: 100%;
          }
        }

        .adm-list-item-content-main {
          flex: 1;
          flex: auto;
          font-size: 34px;
        }

        .adm-list-item-description {
          .tips {
            display: flex;
            justify-content: space-between;

            span {
              float: left;
              font-size: 24px;
              color: rgb(0, 0, 0);
              font-family: 微软雅黑;
              line-height: 32px;
            }
          }
        }
      }
    }
  }
}
</style>