<template>
  <a class="textImg" @click="tabClick">
    <div class="adm-list-item-content">
      <div class="adm-list-item-content-prefix">
        <div class="adm-image" style="--width: 100px; --height: 80px; margin: 10px">
          <img class="adm-image-img" :src="props.data.thumb" style="object-fit: cover; display: block" />
        </div>
      </div>
      <div class="adm-list-item-content-main">
        {{ props.data.title }}
        <div class="adm-list-item-description">
          <div class="tips" style="margin-top: 10px">
            <span style="color: #000">安辛资讯</span>
            <span style="color: #999">{{ getReading() }}阅读</span>
          </div>
        </div>
      </div>
    </div>
  </a>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from 'vue-router';

const { push } = useRouter();
const props = defineProps({
  data: Object,
});

const getReading = () => {
  return String(props?.data?.orgId)?.substring?.(4);
}

const tabClick = () => {
  push({
    path: '/detail',
    query: {
      id: props.data?.id
    }
  });
}
</script>
