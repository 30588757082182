<template>
  <div class="nav">
    <div :class="[
        'adm-jumbo-tabs-tab-wrapper',
        activeTab == idx ? 'adm-jumbo-tabs-tab-active' : '',
      ]" v-for="(item, idx) in props.navList" :key="idx" @click="(e) => {
        e.target.scrollIntoView();
        navClick(item, idx)
      }">
      <div class="adm-jumbo-tabs-tab">
        <div class="adm-jumbo-tabs-tab-title">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  navList: Array,
  activeTab: Number,
  tabIndex: Number,
  tabChange: Function
});
const emit = defineEmits(['update:tabIndex', 'tabChange'])

const activeTab = ref(0);
const navClick = (item, idx) => {
  activeTab.value = idx;
  emit('update:tabIndex', idx);
  emit('tabChange', idx);
  // props.tabChange(idx);
};
</script>

<style lang="less">
.nav {
  width: 100%;
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px 0px;
  // display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  // display: flex;
  // flex-wrap: nowrap;
  // justify-content: flex-start;
  position: relative;
  overflow: auto;
  white-space: nowrap;

  scrollbar-width: none;

  .adm-jumbo-tabs-tab-wrapper {
    width: 20%;
    display: inline-block;
    color: #333;

    .adm-jumbo-tabs-tab {
      padding: 0 16px;
      text-align: center;

      .adm-jumbo-tabs-tab-title {
        line-height: 100px;
        padding: 0 8px;
        font-size: 34px;
      }
    }
  }

  .adm-jumbo-tabs-tab-active {
    color: #a062d4;

    .adm-jumbo-tabs-tab {
      .adm-jumbo-tabs-tab-title {
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 4px;
          background: #a062d4;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}
</style>